<template>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
        <bs-page-title :titleLabel="fromParentTitle" :btnLabel="fromParentBtn" :btnClass="'btn-primary'" :btnToLink="'/tag/add'" :routerSw="true"></bs-page-title>
        <div class="w100">
            <table class="table">
                <thead>
                    <tr>
                        <th>カテゴリ名</th>
                        <th>グループ</th>
                        <th>action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="list in lists" :key="list.id">
                        <td>{{ list.label }}</td>
                        <td>{{ list.group_label }}</td>
                        <td>
                            <router-link :to="{name: 'tag_detail', params:{id:list.id}}"  href="#">詳細</router-link>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </main>
</template>

<script>
import { mapState } from 'vuex'
import BsPageTitle from '../../molecules/BsPageTitle.vue'
// import '../../assets/bootstrap/css/sc.css';

export default {
    name: 'TagListView',
    data: function () {
        return {
            'fromParentTitle': '商品カテゴリ一覧',
            'fromParentBtn': '商品カテゴリ登録',
            'email': '',
            'pass': '',
            error: false
        }
    },
    // props: {
    //     lists: {
    //         type: Array,
    //         default: () => []
    //     }
    // },
    components: {
        BsPageTitle,
    },
    computed: mapState({
        lists: state => state.apidata.tag
    }),

    created () {
        this.loadTags();
        this.loadGroups();
    },
    mounted: function(){
        // console.log(this.$route.path);
        // console.log('strage', this.$store.state.article, this.lists);
        // this.fromParentTitle = '商品詳細';
    },

    methods: {
        loadTags () {
            // console.log('tag');
            return this.$store.dispatch('fetchTagLists', {data: {}})
                        .catch(err => Promise.reject(err))
                        .then(() => {
                            console.log('loard complete tag');
                            console.log('this', this.$store.state.apidata.article);
                            // this.$router.push({ path: '/login' })
                        })
                        .finally(() => {
                            console.log('finally', this.$store.state.article);
                        })
        },
        loadGroups () {
            // console.log('tag');
            return this.$store.dispatch('fetchTagGroupLists', {data: {}})
                        .catch(err => Promise.reject(err))
                        .then(() => {
                            console.log('loard complete tag');
                            console.log('thistg', this.$store.state.apidata.tag_group);
                            // this.$router.push({ path: '/login' })
                        })
                        .finally(() => {
                            console.log('finallytg', this.$store.state.apidata.tag_group);
                        })
        }
    }

}
</script>
