import { createApp } from 'vue'
import App from './App.vue'

import axios from 'axios';
import VueAxios from "vue-axios";

import router from './router'
import store from './store'

import gv from './mixin/globalValiables';

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
// import 'bootstrap-icons';
import 'bootstrap-icons/font/bootstrap-icons.css';

import './assets/base/base.css';

import './validate/validate'

import 'feather-icons'

const app = createApp(App);
app.use(router);
app.use(store);
app.mixin(gv);
app.use(VueAxios, axios);
app.mount('#app');
