<template>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
        <bs-page-title :titleLabel="fromParentTitle + btnLabel" :btnLabel="fromParentBtn" :btnClass="'btn-primary'"></bs-page-title>
        <bs-msg-alert :alertClass="alertClass">{{this.alertMessage}}</bs-msg-alert>
        <div class="w100">
            <Form :validation-schema="schema" v-slot="{errors}" @submit.prevent novaformate>
              <div class="row mb-3">
                <div class="col">
                  <label for="ArticleCode" class="form-label">商品コード</label>
                  <Field v-model="article.code"  name="code" class="form-control" id="ArticleCode" />
                  <span class="validate_error_msg">{{errors.code}}</span>
                </div>
                <div class="col">
                  <label for="ArticleLabel" class="form-label">名称</label>
                  <Field v-model="article.label"  name="label" class="form-control" id="ArticleLabel" :rules="isRequired" />
                  <span class="validate_error_msg">{{errors.label}}</span>
                </div>
              </div>
              <div class="row mb-5">
                <div class="col">
                  <label for="ArticleListPrice" class="form-label">定価</label>
                  <Field v-model="article.list_price"  name="list_price" placeholder="定価" class="form-control" id="ArticleListPrice" />
                  <span class="validate_error_msg">{{errors.list_price}}</span>
                </div>
                <div class="col">
                  <label for="ArticlePurchasePrice" class="form-label">仕入価</label>
                  <Field v-model="article.purchase_price"  name="purchase_price" placeholder="仕入価" class="form-control" id="ArticlePurchasePrice" />
                  <span class="validate_error_msg">{{errors.purchase_price}}</span>
                </div>
              </div>
              <div class="row mb-5">
                <div class="col">
                  <label for="ArticleListPrice" class="form-label">タグ</label>
                  <bs-check-box
                    v-model="article.tags"
                    name="tags"
                    :options="tags_list"
                  ></bs-check-box>
                  <span class="validate_error_msg">{{errors.tags}}</span>
                </div>
                <div class="col">
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-12 col-sm-4 mb-2">
                  <router-link to="/article/list" class="btn btn-light btn-outline-secondary">戻る</router-link>
                </div>
                <div class="col-12 col-sm-4 mb-2 text-center">
                  <bs-button :btnClass="'btn-primary'" :disabled="Object.keys(errors).length" @to_click_event="handleClick">{{btnLabel}}</bs-button>
                </div>
              </div>
            </Form>
        </div>
    </main>
</template>

<script>
import { mapState } from 'vuex'
import BsPageTitle from '../../molecules/BsPageTitle.vue'
import BsCheckBox from '../../atoms/BsCheckBox.vue'
import BsButton from '../../atoms/BsButton.vue'
import BsMsgAlert from '../../atoms/BsMsgAlert.vue'
import { Field, Form } from 'vee-validate';

// import '../../../validate/validate.js'

export default {
  name: 'ArticleDataView',
  data: function () {
      return {
        'targetid': this.$route.params.id,
        'fromParentTitle': '商品データ',
        'fromParentBtn': '',
        'article': {},
        'add': true,
        'alertMessage': '',
        'alertClass': 'alert-info',
        'schema': {
          code: 'required|numeric',
          label: 'required',
          list_price: 'required|numeric',
          purchase_price: 'required|numeric',
          tags: 'required',
        }
      }
  },
  components: {
      BsPageTitle,
      BsCheckBox,
      BsButton,
      BsMsgAlert,
      Field,
      Form,
      // ErrorMessage,
  },
  methods: {
      handleClick(){
        return this.$store.dispatch('updateArticle', {data: this.article })
          .then(() => {
            // this.back()
          })
          .finally(
            () => {
              this.alertMessage = this.btnLabel + 'しました';
              this.alertClass = 'alert-success';
            }
          )
          .catch(
            () => {
            err => Promise.reject(err);
              this.alertMessage = this.btnLabel + 'できませんでした';
              this.alertClass = 'alert-danger';
            }
          )
      },
      isNumeric(value){
        var regex = new RegExp(/^[0-9]+$/);
        if(regex.test(value)){
          return true;
        }else{
          return '数値を入力して下さい';
        }
        // return regex.test(value) ? true: '数値を入力して下さい';
      },
      isRequired(value) {
        return value ? true : '必須です';
      }
  },
  computed: mapState({
      tags_list: state => state.apidata.tag,
      btnLabel() {
          if(this.add){
              return '新規登録';
          }
          else{
              return '更新';
          }
      },
  }),
  created () {
      // this.loadLists()
      console.log(Number.isNaN(this.targetid), this.targetid, 'ididc');
      if(Number.isNaN(this.targetid)){
        // console.log('isNaN');
        this.$router.push({ path: '/' })
      }
      if(this.targetid == 'add'){
        console.log('add');
        this.article = {
          'tags': []
        };
      }
      else{
        const ret = !Number.isNaN(this.targetid)
          ? {...this.$store.getters.getArticleById(this.targetid)}
          : {};
        if(Object.keys(ret).length > 0){
          this.add = false;
        }
        this.article = ret;
        // this.mode = ret.length;
      }
      // this.tags_list = this.$store.tags.lists;
  },
  mounted: function(){
      // console.log(this.$route.path);
      // console.log('strage', this.$store.state.article);
      // console.log('this', ...this.$store.getters.getArticleById(1));
      this.fromParentTitle = '商品データ';
      console.log('tags_list', this.tags_list);
      this.alertMessage = this.btnLabel + 'できます';
  },

  // computed: {
  //   article () {
  //     // const id = parseInt(this.$route.params.id);
  //     // console.log(id, 'idid');
  //     console.log('ret', ret);
  //     return ret;
  //   }
  // },
  props: {
    // article: {
    //   type: Object,
    //   required: true,
    //   default: () => {}
    // },
    onupdate: {
      type: Function,
      required: true
    },
  }

}
</script>
