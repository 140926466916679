<template>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
        <bs-page-title :titleLabel="fromParentTitle" :btnLabel="fromParentBtn" :btnClass="'btn-primary'"></bs-page-title>
        <div class="w100">
            <Form :validation-schema="schema" v-slot="{errors}" @submit.prevent novaformate>
              <div class="row mb-3">
                <div class="col">
                  <label for="TagLabel" class="form-label">名称</label>
                  <Field v-model="tag.label"  name="label" placeholder="タグ名" class="form-control" id="TagLabel" />
                  <span class="validate_error_msg">{{errors.label}}</span>
                </div>
              </div>
              <div class="row mb-5">
                <div class="col">
                  <label for="ArticleListPrice" class="form-label">グループ</label>
                  <bs-select
                    name="group_id"
                    :options="tag_group"
                    v-model="tag.group_id"></bs-select>
                  <span class="validate_error_msg">{{errors.group_id}}</span>
                </div>
                <div class="col">
                </div>
               </div>
              <div class="row mb-3">
                <div class="col-12 col-sm-4 mb-2">
                  <router-link to="/tag/list" class="btn btn-light btn-outline-secondary">戻る</router-link>
                </div>
                <div class="col-12 col-sm-4 mb-2 text-center">
                  <bs-button :btnClass="'btn-primary'" :disabled="Object.keys(errors).length" @to_click_event="handleClick">{{btnLabel}}</bs-button>
                  <!-- <button type="submit" @click="update" class="btn btn-primary">{{btnLabel}}</button> -->
                </div>
              </div>
            </Form>
        </div>
    </main>
</template>

<script>
import { mapState } from 'vuex'
import BsPageTitle from '../../molecules/BsPageTitle.vue'
import BsSelect from '../../atoms/BsSelect.vue'
import { Field, Form } from 'vee-validate';
// import BsCheckBox from '../../atoms/BsCheckBox.vue'
// import '../../assets/bootstrap/css/sc.css';

export default {
  name: 'TagDetailView',
  data: function () {
      return {
        'targetid': this.$route.params.id,
        'fromParentTitle': '商品カテゴリデータ',
        'fromParentBtn': '',
        'add': true,
        'alertMessage': '',
        'alertClass': 'alert-info',
        'tag': {},
        'schema': {
          label: 'required',
          group_id: 'required',
        }
      }
  },
  components: {
      BsPageTitle,
      BsSelect,
      Field,
      Form,
      // BsCheckBox,
  },
  methods: {
      handleClick(){
        console.log('component methods', this.tag);
        return this.$store.dispatch('updateTag', {data: this.tag })
          .then(() => {
            // this.back()
          })
          .catch(err => Promise.reject(err))
      }
  },
  computed: mapState({
    tag_group: state => state.apidata.tag_group,
    btnLabel() {
        if(this.add){
            return '新規登録';
        }
        else{
            return '更新';
        }
    },
      // tags_list: state => state.tags.lists
  }),
  created () {
      if(Number.isNaN(this.targetid)){
        this.$router.push({ path: '/' })
      }
      const ret = !Number.isNaN(this.targetid)
        ? {...this.$store.getters.getTagById(this.targetid)}
        : {};
      if(Object.keys(ret).length > 0){
        this.add = false;
      }
      this.tag = ret;
  },
  mounted: function(){
      // console.log(this.$route.path);
      // console.log('strage', this.$store.state.article);
      // console.log('this', ...this.$store.getters.getArticleById(1));
      this.fromParentTitle = '商品カテゴリ詳細';
      this.alertMessage = this.btnLabel + 'できます';
      // console.log('tags_list', this.tags_list);
  },

  // computed: {
  //   article () {
  //     // const id = parseInt(this.$route.params.id);
  //     // console.log(id, 'idid');
  //     console.log('ret', ret);
  //     return ret;
  //   }
  // },
  props: {
    // article: {
    //   type: Object,
    //   required: true,
    //   default: () => {}
    // },
    onupdate: {
      type: Function,
      required: true
    },
  }

}
</script>
