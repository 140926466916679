<template>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
        <bs-page-title :titleLabel="fromParentTitle + btnLabel" :btnLabel="fromParentBtn" :btnClass="'btn-primary'"></bs-page-title>
        <bs-msg-alert :alertClass="alertClass">{{this.alertMessage}}</bs-msg-alert>
        <div class="w100">
            <Form :validation-schema="schema" v-slot="{errors}" @submit.prevent novaformate validate-on-mount>
              <div class="row mb-3">
                <div class="col">
                  <label for="StockArticleId" class="form-label">商品</label>
                  <bs-select
                    name="article_id"
                    :options="article_list"
                    v-model="stock.article_id"
                    :nullOption="true"></bs-select>
                  <span class="validate_error_msg">{{errors.article_id}}</span>
                </div>
                <div class="col">
                  <label for="StockLabel" class="form-label">仕入部署</label>
                  <bs-select
                    name="department_id"
                    :options="department_list"
                    v-model="stock.department_id"
                    :nullOption="true"></bs-select>
                  <span class="validate_error_msg">{{errors.department_id}}</span>
                </div>
              </div>
              <div class="row mb-5">
                <div class="col">
                  <label for="StockNum" class="form-label">仕入個数</label>
                  <Field v-model="stock.num" name="num" placeholder="個数" class="form-control" id="StockNum" />
                  <span class="validate_error_msg">{{errors.num}}</span>
                </div>
                <div class="col">
                  <label for="StockRetailPrice" class="form-label">小売価格</label>
                  <Field v-model="stock.retail_price" name="retail_price" placeholder="価格" class="form-control" id="StockRetailPrice" />
                </div>
                <div class="col">
                  <label for="StockPurchaseDate" class="form-label">仕入日</label>
                  <Field v-model="stock.purchase_date" type="date" name="purchase_date" placeholder="日付" class="form-control" id="StockPurchaseDate" />
                  <span class="validate_error_msg">{{errors.purchase_date}}</span>
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-12 col-sm-4 mb-2">
                  <router-link to="/stock/list" class="btn btn-light btn-outline-secondary">戻る</router-link>
                </div>
                <div class="col-12 col-sm-4 mb-2 text-center">
                  <bs-button :btnClass="'btn-primary'" :disabled="(Object.keys(errors).length > 0) || btnCanClick" @to_click_event="handleClick">{{btnLabel}}</bs-button>
                  <!-- <button type="submit" @click="update" class="btn btn-primary">{{btnLabel}}</button> -->
                </div>
              </div>
            </Form>
        </div>
    </main>
</template>

<script>
import { mapState } from 'vuex'
import BsPageTitle from '../../molecules/BsPageTitle.vue'
import BsSelect from '../../atoms/BsSelect.vue'
import BsButton from '../../atoms/BsButton.vue'
import BsMsgAlert from '../../atoms/BsMsgAlert.vue'
import { Field, Form } from 'vee-validate';
// import '../../assets/bootstrap/css/sc.css';
import Common from '../../../common/common'

export default {
  name: 'ArticleListView',
  data: function () {
      return {
        'targetid': this.$route.params.id,
        'fromParentTitle': '在庫データ',
        'fromParentBtn': '',
        'add': true,
        'formChange': false,
        'mounted': false,
        'alertMessage': '',
        'alertClass': 'alert-info',
        'stock': {},
        'schema': {
          article_id: 'required',
          department_id: 'required',
          num: 'required|numeric',
          retail_price: '',
          purchase_date: 'required'
        }
      }
  },
  components: {
      BsPageTitle,
      BsSelect,
      BsButton,
      BsMsgAlert,
      Field,
      Form,
  },
  methods: {
    handleClick(){
      console.log('component methods', this.stock.num, this.stock.retail_price);
      // Form.submit();
      return this.$store.dispatch('updateStock', {data: this.stock })
        .then(() => {
          // this.back()
        })
        .catch(err => Promise.reject(err))
    }
  },
  computed: mapState({
      article_list: state => state.apidata.article,
      department_list: state => state.apidata.department,
      btnCanClick: function() {
        console.log('btncanclick', Object.keys(this.stock).length);
        if(!this.add){
          return false;
        }else{
          return false;
        }
        // return function(errors){
        //   console.log('errors', errors);
        //   console.log('Form', Form);
        //   if(this.mounted == false){
        //     return true;
        //   }
        //   else if(Object.keys(errors).length > 0){
        //     return true;
        //   }
        //   else{
        //     return false;
        //   }
        // }
      },
      btnLabel() {
          if(this.add){
              return '新規登録';
          }
          else{
              return '更新';
          }
      },
  }),
  // watch: {
  //   stock:{
  //     handler: function (val, oldVal){
  //         console.log('function val oldVal', val, oldVal);
  //       if(val != oldVal){
  //         console.log('formChange != ', val, oldVal);
  //         this.formChange = true;
  //       }
  //       console.log('formChange', this.formChange);
  //     },
  //     deep: true
  //   }
  // },
  created () {
      // this.loadLists()
      console.log(this.targetid, 'ididc');
      if(Number.isNaN(this.targetid)){
        this.$router.push({ path: '/' })
      }
      const ret = !Number.isNaN(this.targetid)
        ? {...this.$store.getters.getStockById(this.targetid)}
        : {};
      if(Object.keys(ret).length > 0){
        this.add = false;
        this.stock = ret;
      }else{
        // const date = new date();
        console.log('Common.getToday()', Common.getToday());
        this.stock = {
          num: '',
          retail_price: '',
          department_id: '',
          article_id: '',
          purchase_date: Common.getToday()
        }
      }
      // this.tags_list = this.$store.tags.lists;
  },
  mounted: function(){
    this.fromParentTitle = '在庫データ';
    this.alertMessage = this.btnLabel + 'できます';
    console.log(this.mounted);
    this.formChange = true;
    console.log('mounted 2', this.mounted);
    // this.$watch(this.stock, function() {
    //   //
    //   console.log('change');
    //       this.formChange = true;
    // }, {
    //   immediate: true // オプション
    // });
  },
}
</script>
