<template>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
        <bs-page-title :titleLabel="fromParentTitle" :btnLabel="fromParentBtn" :btnClass="'btn-primary'"></bs-page-title>
        <div class="w100">
            <form @submit.prevent>
              <div class="row mb-3">
                <div class="col">
                  <label for="ArticleCode" class="form-label">商品コード</label>
                  <input type="text" class="form-control" id="ArticleCode" placeholder="" v-model="article.code">
                </div>
                <div class="col">
                  <label for="ArticleLabel" class="form-label">名称</label>
                  <input type="text" class="form-control" id="ArticleLabel" placeholder="商品名" v-model="article.label">
                </div>
              </div>
              <div class="row mb-5">
                <div class="col">
                  <label for="ArticleListPrice" class="form-label">定価</label>
                  <input type="text" class="form-control" id="ArticleListPrice" placeholder="定価" v-model="article.list_price">
                </div>
                <div class="col">
                  <label for="ArticlePurchasePrice" class="form-label">仕入価</label>
                  <input type="text" class="form-control" id="ArticlePurchasePrice" placeholder="仕入価" v-model="article.purchase_price">
                </div>
              </div>
              <div class="row mb-5">
                <div class="col">
                  <label for="ArticleListPrice" class="form-label">タグ</label>
                  <bs-check-box
                    v-model="article.tags"
                    name="tags"
                    :options="tags_list"
                  ></bs-check-box>
                </div>
                <div class="col">
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-12 col-sm-4 mb-2">
                  <router-link to="/article/list" class="btn btn-light btn-outline-secondary">戻る</router-link>
                </div>
                <div class="col-12 col-sm-4 mb-2 text-center">
                  <button type="submit" @click="update" class="btn btn-primary">更新</button>
                </div>
              </div>
            </form>
        </div>
    </main>
</template>

<script>
import { mapState } from 'vuex'
import BsPageTitle from '../../molecules/BsPageTitle.vue'
import BsCheckBox from '../../atoms/BsCheckBox.vue'
// import '../../assets/bootstrap/css/sc.css';

export default {
  name: 'ArticleListView',
  data: function () {
      return {
        'targetid': this.$route.params.id,
        'fromParentTitle': '商品データ',
        'fromParentBtn': '',
        'article': {},
        // 'tags_list': {},
        // 'tags_list': [
        //   {
        //     label: 'test',
        //     value: '1'
        //   },
        //   {
        //     label: '2test',
        //     value: '2'
        //   },
        //   {
        //     label: 't3est',
        //     value: '3'
        //   },
        // ]
      }
  },
  components: {
      BsPageTitle,
      BsCheckBox,
  },
  methods: {
      // loadLists () {
      //     return this.$store.dispatch('fetchArticleLists')
      //                 .catch(err => Promise.reject(err))
      //                 .then(() => {
      //                     console.log('loard complete');
      //                     console.log('this', this.$store.state.article);
      //                     // this.$router.push({ path: '/login' })
      //                 })
      //                 .finally(() => {
      //                     console.log('finally', this.$store.state.article);
      //                 })
      // }
      // tags_checked(check_tags){
      //   console.log('tags_checked:', check_tags);
      // },

      update(){
        console.log('component methods', this.article);
        return this.$store.dispatch('updateArticle', {data: this.article })
          .then(() => {
            // this.back()
          })
          .catch(err => Promise.reject(err))
      }
  },
  computed: mapState({
      tags_list: state => state.apidata.tag
  }),
  created () {
      // this.loadLists()
      console.log(this.targetid, 'ididc');
      if(Number.isNaN(this.targetid)){
        this.$router.push({ path: '/' })
      }
      const ret = !Number.isNaN(this.targetid)
        ? {...this.$store.getters.getArticleById(this.targetid)}
        : {};
      this.article = ret;
      // this.tags_list = this.$store.tags.lists;
  },
  mounted: function(){
      // console.log(this.$route.path);
      // console.log('strage', this.$store.state.article);
      // console.log('this', ...this.$store.getters.getArticleById(1));
      this.fromParentTitle = '商品詳細';
      console.log('tags_list', this.tags_list);
  },

  // computed: {
  //   article () {
  //     // const id = parseInt(this.$route.params.id);
  //     // console.log(id, 'idid');
  //     console.log('ret', ret);
  //     return ret;
  //   }
  // },
  props: {
    // article: {
    //   type: Object,
    //   required: true,
    //   default: () => {}
    // },
    onupdate: {
      type: Function,
      required: true
    },
  }

}
</script>
