<template>
    <div :class="'alert ' + this.class">
        {{ message}}
    </div>
</template>

<script>
export default {
  name: 'BsMessageBlock',
  props: {
    message: {
      type: String,
      default: 'message'
    },
    title: {
      type: String,
      default: 'message'
    },
    class: {
      type: String,
      default: 'alert-info'
    }
  },
}
</script>
