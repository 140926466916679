import client from './client'
import config from '../const'

export default {
  login: authInfo => {
    return new Promise((resolve, reject) => {
    //   client.post('/auth/login', authInfo)
      // console.log('authinfo', authInfo);
      let params = new URLSearchParams();
      params.append('login_id', authInfo.login_id);
      params.append('login_pass', authInfo.login_pass);
      client.post(config.BASE_API_URL + '/manage/login', params)
        .then(res => resolve({ token: res.data.body.token, userId: res.data.body.userId, body: res.data }))
        .catch(err => {
          console.log(err, err.response);
          reject(new Error(err.response.message || err.message))
        })
    })
  },
// 'regiapi-manage-token'
  logout: token => {
    return new Promise((resolve, reject) => {
      console.log('logout token', token);
      client.delete(config.BASE_API_URL + '/manage/logout', { headers: { [config.BASE_API_HEADER_KEY] : token } })
        .then(() => resolve())
        .catch(err => {
          reject(new Error(err.response.data.message || err.message))
        })
    })
  }
}
