<template>
    <div id="login" class="text-center container">
        <h1 class="h4 mb-3 font-weight-normal">ログアウト</h1>
        <div class="alert alert-warning">
            {{ logout_msg }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'Logout',
    data: function () {
        return {
            logout_msg: 'ログアウトします。'
        }
    },
    methods:{
        handleLogout(authInfo){
            console.log('handleLogout');
            return this.$store.dispatch('logout', authInfo)
                .then(() => {
                    console.log('logout success', authInfo);
                    this.$router.push({ path: '/' })
                })
                .catch(err => this.throwReject(err));
        },
        throwReject (err) { return Promise.reject(err) }
    },
    beforeCreate(){
        console.log('beforeCreate');
    },
    created() {
        console.log('created');
    },
    beforeMount() {
        console.log('beforeMount');
    },
    mounted(){
        this.handleLogout();
        console.log('mounted');
    }
}
</script>


<style scoped>
@import "../../assets/bootstrap/css/signin.css";
</style>
