export default {
    getArticleById: state => id => {
        //   const articles = [];
        //返す準備
        let article = '';
        // console.log('getter', id);
        // console.log('state.article.lists', state.article.lists);
        //state.articlのデータからIDが一致するものを取得
        state.apidata.article.forEach(list => {
        // articles.push(list);
        // console.log('list.items.id', list.id);
            if(id == list.id){
                article = list;
            }
        })
        // console.log('articles', articles);
        // const returndata = articles.find(article => article.id === id);
        // console.log('article', article);
        //一致するものがなければ返す
        if(article == '') article = false;
        return article;
    },
    getTagById: state => id => {
        //   const articles = [];
        //返す準備
        let tag = '';
        // console.log('getter', id);
        // console.log('state.article.lists', state.article.lists);
        //state.articlのデータからIDが一致するものを取得
        state.apidata.tag.forEach(list => {
        // articles.push(list);
        // console.log('list.items.id', list.id);
            if(id == list.id){
                tag = list;
            }
        })
        // console.log('articles', articles);
        // const returndata = articles.find(article => article.id === id);
        // console.log('article', article);
        //一致するものがなければ返す
        if(tag == '') tag = false;
        return tag;
    },
    getDepartmentById: state => id => {
        let data = '';
        //stateのデータからIDが一致するものを取得
        state.apidata.department.forEach(list => {
            if(id == list.id){
                data = list;
            }
        })
        //一致するものがなければ返す
        if(data == '') data = false;
        return data;
    },
    getDeviceById: state => id => {
        let data = '';
        //stateのデータからIDが一致するものを取得
        state.apidata.device.forEach(list => {
            if(id == list.id){
                data = list;
            }
        })
        //一致するものがなければ返す
        if(data == '') data = false;
        // console.log('getDeviceById', data);
        return data;
    },
    getStockById: state => id => {
        let data = '';
        //stateのデータからIDが一致するものを取得
        state.apidata.stock.forEach(list => {
            if(id == list.id){
                data = list;
            }
        })
        //一致するものがなければ返す
        if(data == '') data = false;
        return data;
    },
    getSalesById: state => id => {
        let data = '';
        //stateのデータからIDが一致するものを取得
        state.apidata.sales.forEach(list => {
            if(id == list.id){
                data = list;
            }
        })
        //一致するものがなければ返す
        if(data == '') data = false;
        return data;
    },
    getCustomerById: state => id => {
        let data = '';
        //stateのデータからIDが一致するものを取得
        state.apidata.customer.forEach(list => {
            if(id == list.id){
                data = list;
            }
        })
        //一致するものがなければ返す
        if(data == '') data = false;
        return data;
    },
    getEventById: state => id => {
        let data = '';
        //stateのデータからIDが一致するものを取得
        state.apidata.event.forEach(list => {
            if(id == list.id){
                data = list;
            }
        })
        //一致するものがなければ返す
        if(data == '') data = false;
        return data;
    },
}
