<template>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
        <bs-page-title :titleLabel="fromParentTitle" :btnLabel="fromParentBtn" :btnClass="'btn-primary'"></bs-page-title>
        <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
            <h1 class="h2">main data list</h1>

        </div>
    </main>
</template>

<script>
import BsPageTitle from '../molecules/BsPageTitle.vue'
// import '../../assets/bootstrap/css/sc.css';

export default {
    name: 'BsDataListView',
    data: function () {
        return {
            'fromParentTitle': '本当のタイトル',
            'fromParentBtn': '本当のボタン',
            'email': '',
            'pass': '',
            error: false
        }
    },
    components: {
        BsPageTitle,
    },
}
</script>
