import client from './client'
import config from '../const'

export default {
    fetch: (token, {data}) => {
        return new Promise((resolve, reject) => {
            const params = new URLSearchParams();
            if(data != undefined){
                const keys = Object.keys(data);
                for(let i=0; i < keys.length; i++){
                    params.append('sprm[' + keys[i] + ']', data[keys[i]]);
                }
            }
            client.post(config.BASE_API_URL + '/article_tag_group/lists', params, { withCredentials: true, headers: {[config.BASE_API_HEADER_KEY]: token } })
            .then(res => {
                console.log(res.data);
                resolve({ tag_group: res.data.body })
            })
            .catch(err => {
                reject(new Error(err.response.data.message || err.message))
            })
        })
    },

  update: (token, { data }) => {
    return new Promise((resolve, reject) => {
        console.log(' id, code, label, list_price, purchase_price', data);
        const params = new URLSearchParams();
        // let sprm = {};
        if(data != undefined){
            const keys = Object.keys(data);
            for(let i=0; i < keys.length; i++){
                console.log('iprm[' + keys[i] + ']', data[keys[i]]);
                // sprm[keys[i]] = data[keys[i]];
                params.append('iprm[' + keys[i] + ']', data[keys[i]]);
            }
        }
        console.log('params', params);
      client.post(config.BASE_API_URL + `/article_tag_group/edit/${data.id}`, params, { headers: {[config.BASE_API_HEADER_KEY]: token } })
        .then(() => resolve())
        .catch(err => {
            console.log(err);
          reject(new Error(err.response.data.message || err.message))
        })
    })
  },
}
