import Template from '../components/templates/BsTemplateView.vue'
import Login from '../components/templates/BsLoginView.vue'
import Logout from '../components/templates/BsLogoutView.vue'
import Error from '../components/templates/BsErrorView.vue'
import PagesList from '../components/templates/BsDataListView.vue'
import DashBoard from '../components/templates/BsDashBoardView.vue'
import ArticleList from '../components/templates/Article/ListView.vue'
import ArticleData from '../components/templates/Article/DataView.vue'
import TagList from '../components/templates/Tag/ListView.vue'
import TagData from '../components/templates/Tag/DataView.vue'
import DepartmentList from '../components/templates/Department/ListView.vue'
import DepartmentData from '../components/templates/Department/DataView.vue'
import DeviceList from '../components/templates/Device/ListView.vue'
import DeviceData from '../components/templates/Device/DataView.vue'
import StockList from '../components/templates/Stock/ListView.vue'
import StockData from '../components/templates/Stock/DataView.vue'
import SalesList from '../components/templates/Sales/ListView.vue'
import SalesData from '../components/templates/Sales/DataView.vue'
import CustomerList from '../components/templates/Customer/ListView.vue'
import CustomerData from '../components/templates/Customer/DataView.vue'
import EventList from '../components/templates/Event/ListView.vue'
import EventData from '../components/templates/Event/DataView.vue'

export default [
    {
        path: '/',
        name: 'base',
        component: Template,
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: '/',
                name: 'dashboard',
                component: DashBoard,
            },
            {
                path: '/pages/list',
                name: 'pages_list',
                component: PagesList,
            }
        ]
    },
    {
        path: '/article',
        name: 'article',
        component: Template,
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: '/article/:id',
                name: 'article_detail',
                component: ArticleData,
            },
            {
                path: '/article/list',
                name: 'article_list',
                component: ArticleList,
            }
        ]
    },
    {
        path: '/tag',
        name: 'tag',
        component: Template,
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: '/tag/:id',
                name: 'tag_detail',
                component: TagData,
            },
            {
                path: '/tag/list',
                name: 'tag_list',
                component: TagList,
            }
        ]
    },
    {
        path: '/department',
        name: 'department',
        component: Template,
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: '/department/:id',
                name: 'department_detail',
                component: DepartmentData,
            },
            {
                path: '/department/list',
                name: 'department_list',
                component: DepartmentList,
            }
        ]
    },
    {
        path: '/device',
        name: 'device',
        component: Template,
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: '/device/:id',
                name: 'device_detail',
                component: DeviceData,
            },
            {
                path: '/device/list',
                name: 'device_list',
                component: DeviceList,
            }
        ]
    },
    {
        path: '/stock',
        name: 'stock',
        component: Template,
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: '/stock/:id',
                name: 'stock_detail',
                component: StockData,
            },
            {
                path: '/stock/list',
                name: 'stock_list',
                component: StockList,
            }
        ]
    },
    {
        path: '/sales',
        name: 'sales',
        component: Template,
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: '/sales/:id',
                name: 'sales_detail',
                component: SalesData,
            },
            {
                path: '/sales/list',
                name: 'sales_list',
                component: SalesList,
            },
            {
                path: '/sales/input',
                name: 'sales_input',
                component: SalesData,
            },
        ]
    },
    {
        path: '/customer',
        name: 'customer',
        component: Template,
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: '/customer/:id',
                name: 'customer_detail',
                component: CustomerData,
            },
            {
                path: '/customer/list',
                name: 'customer_list',
                component: CustomerList,
            },
        ]
    },
    {
        path: '/event',
        name: 'event',
        component: Template,
        meta: {
            requiresAuth: true
        },
        children: [
            {
                path: '/event/:id',
                name: 'event_detail',
                component: EventData,
            },
            {
                path: '/event/list',
                name: 'event_list',
                component: EventList,
            },
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
    },
    {
        path: '/logout',
        name: 'logout',
        component: Logout
    },
    {
        path: '/data/list',
        name: 'DataList',
        component: Template,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/error',
        name: 'error',
        component: Error
    },
    {
        path: "/:pathMatch(.*)*",
        redirect: './error'
    }
]
