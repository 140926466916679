<template>
    <div id="home">
        <BsHeaderView></BsHeaderView>
          <div class="container-fluid">
              <div class="row row-eq-height">
                  <bs-side-menu-view></bs-side-menu-view>
                  <router-view></router-view>
              </div>
          </div>
        <BsFooterView />
    </div>
</template>
<style scoped>
  .row-eq-height {
    display: flex;
    flex-wrap: wrap;
  }
</style>
<script>
// import Vue from 'vue'
import BsHeaderView from '../organisms/BsHeaderView.vue'
import BsFooterView from '../organisms/BsFooterView.vue'
import BsSideMenuView from '../organisms/BsSideMenuView.vue'
import { mapState } from 'vuex'
export default {
  name: 'home',
  data: function(){
      return {
          test: 'tetestte',
        //   count: this.$store.state.count
      }
  },
  computed:
    // count(){
    //   return this.$store.state.count
    // },
    mapState(['count']),

  components: {
    BsHeaderView,
    BsFooterView,
    BsSideMenuView,
  },
  methods:{
    // plus(){
    //     console.log('Home +');
    //     this.$store.commit('Splus')
    // },
    // minus(){
    //     this.$store.commit('Sminus')
    // },
  },
//   computed: {
//     count(){
//       return 0
//     },
//     test(){
//       return 0
//     },
//   },
//   watch: {
//     test: function(newVal, oldVal){
//       console.log(oldVal + '=>' + newVal);
//     }
//   },
//   methods:{
//     onincrement(){
//       this.$store.commit('incrementCnt')
//     },
//     ondecrement(){
//       this.$store.commit('decrementCnt')
//     },
//     actionDo(){
//       this.$store.commit('Do')
//     },
//     actionNotDo(){
//       this.$store.commit('Not')
//     }
//   }
}
</script>

<style scoped>
    @import "../../assets/bootstrap/css/dashboard.css";
</style>
