<template>
    <nav id="sidebarMenu" class="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse">
        <div class="position-sticky pt-3">
            <ul class="nav flex-column">
                <li class="nav-item">
                    <router-link to="/" class="nav-link" aria-current="page" href="#">
                        <vue-feather type="home" />
                        ダッシュボード
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link to="/pages/list" class="nav-link" aria-current="page" href="#">
                        <i class="bi bi-inboxes"></i>
                        在庫管理
                    </router-link>
                </li>
            </ul>
            <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                <span>集計データ</span>
                <i class="bi bi-graph-up"></i>
            </h6>
            <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                <span>商品データ</span>
                <vue-feather type="database" />
            </h6>
            <ul class="nav flex-column">
                <li class="nav-item">
                    <router-link to="/article/list" class="nav-link" aria-current="page" href="#">
                        <i class="bi bi-box-seam"></i>
                        商品データ設定-
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link to="/tag/list" class="nav-link" aria-current="page" href="#">
                        <i class="bi bi-tags"></i>
                        商品種別設定-
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link to="/stock/list" class="nav-link" aria-current="page" href="#">
                        <i class="bi bi-inboxes"></i>
                        在庫管理-
                    </router-link>
                </li>
            </ul>
            <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                <span>管理データ設定</span>
                <i class="bi bi-pencil-square"></i>
            </h6>
            <ul class="nav flex-column">
                <li class="nav-item">
                    <router-link to="/department/list" class="nav-link" aria-current="page" href="#">
                        <i class="bi bi-diagram-3"></i>
                        部署管理-
                    </router-link>
                </li>
                <li class="nav-item">
                    <router-link to="/device/list" class="nav-link" aria-current="page" href="#">
                        <i class="bi bi-tablet"></i>
                        端末管理-
                    </router-link>
                </li>
            </ul>
            <h6 class="sidebar-heading d-flex justify-content-between align-items-center px-3 mt-4 mb-1 text-muted">
                <span>各種設定</span>
                <i class="bi bi-gear"></i>
            </h6>
            <ul class="nav flex-column">
                <li class="nav-item">
                    <router-link to="/pages/list" class="nav-link" aria-current="page" href="#">
                        <i class="bi bi-file-bar-graph"></i>
                        マスタメンテナンス
                    </router-link>
                </li>
            </ul>
        </div>
    </nav>
</template>
<style scoped>
    #sidebarMenu {
        position: relative !important;
        padding-top: 0 !important;
    }
</style>
<script>
import 'feather';
import 'vue-feather'
import VueFeather from 'vue-feather';
// import { ActivityIcon } from "vue-feather-icons";
export default {
    name: 'BsSideMenuView',
    components: {
        VueFeather
    }
}
</script>
