import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'

import actions from './actions'
import getters from './getters'
import mutations from './mutations'

// 状態`Auth`と状態`Board`をVuexのstateで一元管理できるよう定義する
const state = {
  auth: { // 状態`Auth`
    token: localStorage.getItem('token'), // `token`は locale storage から取得する
    userId: null // `userId`はnullで初期化
  },
  apidata: {
    account_department: [],
    article: [],
    tag: [],
    tag_group: [],
    stock: [],
    device: [],
    sales_article: [],
    sales_stock: [],
    department: [],
    customer: [],
    event: [],
  },
  article: {
    lists: []
  },
  tags: {
    lists: []
  },
  groups: {
    lists: []
  },
  stocks: {
    lists: []
  },
  devices: {
    lists: []
  },
  sales: {
    lists: []
  },
  sales_article: {
    lists: []
  },
  sales_stock: {
    lists: []
  },
  departments: {
    lists: []
  },
    // board: { // 状態`Board`
    //   lists: [] // 状態`TaskList`は空で初期化
    // },
    // dragging: { // ドラッグ&ドロップされるタスクを処理するための状態を格納する
    //   target: null, // ドラッグ&ドロップ対象のタスクID
    //   from: null, // ドラッグ元のタスクリストID
    //   to: null // ドロップ先のタスクリストID
    // }
}
export default createStore({
    strict: true,
    state,
    getters,
    actions,
    mutations,
    plugins: [createPersistedState({
        key: 'app',
        storage: window.sessionStorage
    })]
})
