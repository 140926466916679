<template>
    <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4">
        <bs-page-title :titleLabel="fromParentTitle" :btnLabel="fromParentBtn" :btnClass="'btn-primary'"></bs-page-title>
        <div class="w100">
            <form @submit.prevent>
              <div class="row mb-3">
                <div class="col">
                  <label for="Label" class="form-label">名称</label>
                  <input type="text" class="form-control" id="Label" placeholder="顧客名" v-model="device.label">
                </div>
              </div>
              <div class="row mb-3">
                <div class="col-12 col-sm-4 mb-2">
                  <router-link to="/customer/list" class="btn btn-light btn-outline-secondary">戻る</router-link>
                </div>
                <div class="col-12 col-sm-4 mb-2 text-center">
                  <button type="submit" @click="update" class="btn btn-primary">更新</button>
                </div>
              </div>
            </form>
        </div>
    </main>
</template>

<script>
import { mapState } from 'vuex'
import BsPageTitle from '../../molecules/BsPageTitle.vue'
// import BsSelect from '../../atoms/BsSelect.vue'
// import BsCheckBox from '../../atoms/BsCheckBox.vue'
// import '../../assets/bootstrap/css/sc.css';

export default {
  name: 'CustomerDetailView',
  data: function () {
      return {
        'targetid': this.$route.params.id,
        'fromParentTitle': '顧客データ',
        'fromParentBtn': '',
        'customer': {},
      }
  },
  components: {
      BsPageTitle,
    //   BsSelect,
      // BsCheckBox,
  },
  methods: {
      update(){
        console.log('component methods', this.customer);
        return this.$store.dispatch('updateCustomer', {data: this.customer })
          .then(() => {
            // this.back()
          })
          .catch(err => Promise.reject(err))
      }
  },
  computed: mapState({
    device: state => state.apidata.customer,
  }),
  created () {
      if(Number.isNaN(this.targetid)){
        this.$router.push({ path: '/' })
      }
      const ret = !Number.isNaN(this.targetid)
        ? {...this.$store.getters.getCustomerById(this.targetid)}
        : {};
      this.device = ret;
  },
  mounted: function(){
      // console.log(this.$route.path);
      // console.log('strage', this.$store.state.article);
      // console.log('this', ...this.$store.getters.getArticleById(1));
      this.fromParentTitle = '顧客データ詳細';
      // console.log('tags_list', this.tags_list);
  },

  // computed: {
  //   article () {
  //     // const id = parseInt(this.$route.params.id);
  //     // console.log(id, 'idid');
  //     console.log('ret', ret);
  //     return ret;
  //   }
  // },
  props: {
    // article: {
    //   type: Object,
    //   required: true,
    //   default: () => {}
    // },
    onupdate: {
      type: Function,
      required: true
    },
  }

}
</script>
