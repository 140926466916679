<template>
    <form class="form-signin" novalidate>
        <bs-message-block v-show="true" :message="messageBody" :class="messageClass">メッセージ</bs-message-block>
        <label for="inputEmail" class="sr-only">ログインID</label>
        <input type="text" v-model="login_id" class="form-control" placeholder="ログインID">
        <label for="inputPassword" class="sr-only">Password</label>
        <input type="password" v-model="login_pass" class="form-control" placeholder="パスワード">
        <div class="checkbox mb-3">
        <label>
        <input type="checkbox" value="remember-me"> Remember me
        </label>
        </div>
        <bs-button :btnClass="'btn-primary btn-block'" @click="handleClick">ログイン</bs-button>
    </form>
</template>

<script>
import BsMessageBlock from '../atoms/BsMessageBlock.vue'
import BsButton from '../atoms/BsButton.vue'
// メールアドレスのフォーマットをチェックする正規表現
// const REGEX_EMAIL = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
const required = val => !!val.trim()

export default {
    name: 'BsLoginForm',
    data: function () {
        return {
            login_id: 'test@test.com',
            login_pass: 'testTes10!',
            progress: false,
            error: false
        }
    },
    props: {
        onlogin: {
            type: Function,
            required: true,
        }
    },
    computed: {
        validation () { // emailとpasswordのバリデーション
            return {
                login_id: {
                    required: required(this.login_id),
                    // format: REGEX_EMAIL.test(this.login_id)
                },
                login_pass: {
                    required: required(this.login_pass)
                }
            }
        },

        valid () {
            const validation = this.validation // 先に定義したvalidationを用いて可否を返す
            const fields = Object.keys(validation)
            let valid = true
            for (let i = 0; i < fields.length; i++) {
                const field = fields[i]
                valid = Object.keys(validation[field])
                .every(key => validation[field][key])
                if (!valid) { break }
            }
            console.log('valid', valid);
            return valid
        },

        disableLoginAction () { // validを使ってログイン処理の可否、progressは後述
            return !this.valid || this.progress
        },
        messageClass(){
            if(!this.valid || this.error != ''){
                return 'alert-danger';
            }else{
                return 'alert-info';
            }
        },
        messageBody(){
            if(this.error != ''){
                return this.error;
            }
            if(!this.valid){
                return 'ログイン情報を入力してください';
            }else{
                return 'ログインしてください';
            }
        }
    },
    components: {
        BsButton,
        BsMessageBlock,
    },
    methods:{
        handleClick (ev) {
            if (this.disableLoginAction) { return } // 不備があればログイン処理が実行されないようガード
            console.log(ev)
            this.progress = true // ログイン処理実行中をあらわす
            this.error = ''

            this.$nextTick(() => {
                this.onlogin({ login_id: this.login_id, login_pass: this.login_pass })
                .catch(err => {
                    this.error = err.message
                })
                .then(() => {
                    this.progress = false
                })
            })
        }
    },
}
</script>
