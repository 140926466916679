<template>
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 class="h2">{{titleLabel}}</h1>
        <bs-button :btnClass="btnClass" v-if="btnDisp" @to_click_event="handleClick">{{btnLabel}}</bs-button>
    </div>
</template>

<script>
import BsButton from '../atoms/BsButton.vue'
// import BsRouterLink from '../atoms/BsRouterLink.vue'
export default {
    name: 'BsPageTitle',
    props: {
        titleLabel: {
            type: String,
            default: 'ページタイトル'
        },
        btnLabel: {
            type: String,
            default: 'ボタン'
        },
        btnClass: {
            type: String,
            default: 'btn-light btn-outline-secondary'
        },
        routerSw: {
            type: Boolean,
            default: false
        },
        btnToLink: {
            type: String,
            default: '/article/add'
        }
    },
    components: {
        BsButton,
        // BsRouterLink,
    },
    methods: {
        handleClick(ev){
            console.log('parent' + ev, this.btnToLink);
            if(this.routerSw){
                this.$router.push(this.btnToLink);
            }
        }
    },
    computed: {
        btnDisp() {
            if(this.btnLabel == ''){
                return false;
            }
            else{
                return true;
            }
        },
        // toLink() {
        //     console.log(this.btnToLink);
        //     return [`${this.btnToLink}`]
        // },
    // `type`に応じてクラスを動的に生成する
        btnClasses() {
            let cls = '';
            console.log(this.btnClass);

            if(this.btnClass){
                cls = this.btnClass;
            }else{
                cls += 'btn-light btn-outline-secondary';
            }
            return [`btn ${cls}`]
        }
    },
}
</script>
