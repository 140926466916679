// import Vue from 'vue';
import { defineRule } from 'vee-validate';
// configure({
//   validateOnBlur: false, // controls if `blur` events should trigger validation with `handleChange` handler
//   validateOnChange: true, // controls if `change` events should trigger validation with `handleChange` handler
//   validateOnInput: false, // controls if `input` events should trigger validation with `handleChange` handler
//   validateOnModelUpdate: false, // controls if `update:modelValue` events should trigger validation with `handleChange` handler
// });

defineRule('required', value => {
  if (!value || !value.length) {
    return 'この項目は必須です';
  }

  return true;
});

defineRule('numeric', value => {
    var regex = new RegExp(/^[0-9]+$/);
    if(regex.test(value)){
      return true;
    }else{
      return '数値を入力して下さい';
    }
  });

defineRule('email', value => {
  // Field is empty, should pass
  if (!value || !value.length) {
    return true;
  }

  // Check if email
  if (!/[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}/.test(value)) {
    return 'This field must be a valid email';
  }

  return true;
});
