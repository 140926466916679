<template>
  <div class="w-100">
  <div
      :class="classes"
      :disabled="disabled"
    >
      <slot />
  </div>
  </div>
</template>

<script>
export default {
  name: 'BsMsgAlert',

  props: {
    alertClass: {
      type: String,
      default: 'alert-info'
    },
    disabled: {
      type: Boolean,
      default: true
    },
  },

  computed: {
    // `type`に応じてクラスを動的に生成する
    classes () {
      // const cls = this.type === 'text' ? ('-' + this.type) : ''
      // const clses = this.classes.split(',');
      let cls = '';
      // console.log(this.btnClass);

      if(this.alertClass){
          cls = this.alertClass;
      }else{
        cls += 'alert-info';
      }
      return [`alert ${cls}`]
    },
  },

  methods: {

  }
}
</script>
