import * as types from './mutation-types'
import { Article, Auth } from '../api'
import Tag from '../api/tag.js'
import tagGroup from '../api/tag-group'
import Department from '../api/department'
import Device from '../api/device'
import Stock from '../api/stock'
import Sales from '../api/sales'
import Customer from '../api/customer'
import Event from '../api/event'

export default {
  login: ({ commit }, authInfo) => {
    return Auth.login(authInfo)
      .then(({ token, userId/* ,body */ }) => {
        localStorage.setItem('token', token)
        commit(types.AUTH_LOGIN, { token, userId });
        // console.log('logincheck',token, userId, body);
      })
      .catch(err => { throw err })
  },

  fetchArticleLists: ({ commit, state }, data) => {
    return Article.fetch(state.auth.token, data)
      .then(({ article }) => {
        console.log('actions fetch article', article);
        commit(types.FETCH_ALL_ARTICLELIST, article);
        // commit(types.FETCH_ALL_TAGLIST, tags);
      })
      .catch(err => { throw err })
  },
  updateArticle: ({ commit, state }, data) => {
    // console.log('actions.js 開始', data);
    return Article.update(state.auth.token, data)
      .then(() => {
        commit(types.UPDATE_ARTICLE, data)
      })
      .catch(err => { throw err })
  },

  fetchTagLists: ({ commit, state }, data) => {
    return Tag.fetch(state.auth.token, data)
      .then(({ tag }) => {
        // commit(types.FETCH_ALL_ARTICLELIST, tag);
        commit(types.FETCH_ALL_TAGLIST, tag);
      })
      .catch(err => { throw err })
  },
  updateTag: ({ commit, state }, data) => {
    // console.log('actions.js 開始', data);
    return Tag.update(state.auth.token, data)
      .then(() => {
        commit(types.UPDATE_TAG, data)
      })
      .catch(err => { throw err })
  },

  fetchTagGroupLists: ({ commit, state }, data) => {
    return tagGroup.fetch(state.auth.token, data)
      .then(({ tag_group }) => {
        // console.log('actions fetch tags', tag_group);
        // commit(types.FETCH_ALL_ARTICLELIST, lists);
        commit(types.FETCH_ALL_TAGGROUPLIST, tag_group);
      })
      .catch(err => { throw err })
  },

  fetchDepartmentLists: ({ commit, state }, data) => {
    return Department.fetch(state.auth.token, data)
      .then(({ department }) => {
        // console.log('actions fetch tags', tag_group);
        // commit(types.FETCH_ALL_ARTICLELIST, lists);
        commit(types.FETCH_ALL_DEPARTMENTLIST, department);
      })
      .catch(err => { throw err })
  },
  updateDepartment: ({ commit, state }, data) => {
    // console.log('actions.js 開始', data);
    return Department.update(state.auth.token, data)
      .then(() => {
        commit(types.UPDATE_DEPARTMENT, data)
      })
      .catch(err => { throw err })
  },

  fetchDeviceLists: ({ commit, state }, data) => {
    return Device.fetch(state.auth.token, data)
      .then(({ device }) => {
        // console.log('actions fetch tags', tag_group);
        // commit(types.FETCH_ALL_ARTICLELIST, lists);
        commit(types.FETCH_ALL_DEVICELIST, device);
      })
      .catch(err => { throw err })
  },
  updateDevice: ({ commit, state }, data) => {
    // console.log('actions.js 開始', data);
    return Device.update(state.auth.token, data)
      .then(() => {
        commit(types.UPDATE_DEVICE, data)
      })
      .catch(err => { throw err })
  },

  fetchStockLists: ({ commit, state }, data) => {
    return Stock.fetch(state.auth.token, data)
      .then(({ stock }) => {
        // console.log('actions fetch tags', tag_group);
        // commit(types.FETCH_ALL_ARTICLELIST, lists);
        commit(types.FETCH_ALL_STOCKLIST, stock);
      })
      .catch(err => { throw err })
  },
  updateStock: ({ commit, state }, data) => {
    // console.log('actions.js 開始', data);
    return Stock.update(state.auth.token, data)
      .then(() => {
        commit(types.UPDATE_STOCK, data)
      })
      .catch(err => { throw err })
  },

  fetchSalesLists: ({ commit, state }, data) => {
    return Sales.fetch(state.auth.token, data)
      .then(({ sales }) => {
        // console.log('actions fetch tags', tag_group);
        // commit(types.FETCH_ALL_ARTICLELIST, lists);
        commit(types.FETCH_ALL_SALESLIST, sales);
      })
      .catch(err => { throw err })
  },
  updateSales: ({ commit, state }, data) => {
    // console.log('actions.js 開始', data);
    return Sales.update(state.auth.token, data)
      .then(() => {
        commit(types.UPDATE_SALES, data)
      })
      .catch(err => { throw err })
  },

  fetchCustomerLists: ({ commit, state }, data) => {
    return Customer.fetch(state.auth.token, data)
      .then(({ customer }) => {
        // console.log('actions fetch tags', tag_group);
        // commit(types.FETCH_ALL_ARTICLELIST, lists);
        commit(types.FETCH_ALL_CUSTOMERLIST, customer);
      })
      .catch(err => { throw err })
  },
  updateCustomer: ({ commit, state }, data) => {
    // console.log('actions.js 開始', data);
    return Customer.update(state.auth.token, data)
      .then(() => {
        commit(types.UPDATE_CUSTOMER, data)
      })
      .catch(err => { throw err })
  },

  fetchEventLists: ({ commit, state }, data) => {
    return Event.fetch(state.auth.token, data)
      .then(({ event }) => {
        // console.log('actions fetch tags', tag_group);
        // commit(types.FETCH_ALL_ARTICLELIST, lists);
        commit(types.FETCH_ALL_EVENTLIST, event);
      })
      .catch(err => { throw err })
  },
  updateEvent: ({ commit, state }, data) => {
    // console.log('actions.js 開始', data);
    return Event.update(state.auth.token, data)
      .then(() => {
        commit(types.UPDATE_EVENT, data)
      })
      .catch(err => { throw err })
  },

//   fetchLists: ({ commit, state }) => {
//     return List.fetch(state.auth.token)
//       .then(({ lists }) => {
//         commit(types.FETCH_ALL_TASKLIST, lists)
//       })
//       .catch(err => { throw err })
//   },

//   addTask: ({ commit, state }, { listId, name }) => {
//     return Task.add(state.auth.token, { listId, name })
//       .then((task) => {
//         commit(types.ADD_TASK, task)
//       })
//       .catch(err => { throw err })
//   },

//   updateTask: ({ commit, state }, task) => {
//     return Task.update(state.auth.token, task)
//       .then(() => {
//         commit(types.UPDATE_TASK, task)
//       })
//       .catch(err => { throw err })
//   },

//   removeTask: ({ commit, state }, { id, listId }) => {
//     return Task.remove(state.auth.token, { id, listId })
//       .then(() => {
//         commit(types.REMOVE_TASK, { id, listId })
//       })
//       .catch(err => { throw err })
//   },

//   moveTaskFrom: ({ commit, state }, { id, listId }) => {
//     commit(types.MOVE_TASK_FROM, { target: id, from: listId })
//     return Promise.resolve()
//   },

//   moveToTask: ({ commit, state }, { id, listId }) => {
//     commit(types.MOVE_TO_TASK, { target: id, to: listId })
//     return Promise.resolve()
//   },

//   performTaskMoving: ({ commit, state }) => {
//     const { target, from, to } = state.dragging
//     return Task.move(state.auth.token, { id: target, from, to })
//       .then(() => {
//         commit(types.MOVE_TASK_DONE, { target, from, to })
//       })
//       .catch(err => { throw err })
//   },

  logout: ({ commit, state }) => {
    return Auth.logout(state.auth.token)
      .then(() => {
        localStorage.removeItem('token')
        commit(types.AUTH_LOGOUT, { token: null, userId: null })
      })
      .catch(err => { throw err })
  }
}
