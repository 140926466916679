<template>
    <div id="login" class="text-center container">
        <h1 class="h4 mb-3 font-weight-normal">ログイン</h1>
        <BsLoginForm v-bind:onlogin="handleLogin"></BsLoginForm>
    </div>
</template>

<script>
import BsLoginForm from '../molecules/BsLoginForm.vue'
// import '../../assets/bootstrap/css/sc.css';

export default {
    name: 'BsLoginView',
    data: function () {
        return {
            'login_id': '',
            'login_pass': '',
            error: false
        }
    },
    computed: {
        disableLoginAction () { // validを使ってログイン処理の可否、progressは後述
            return false;
        }
    },
    components: {
        BsLoginForm,
    },
    methods:{
        handleLogin(authInfo){
            console.log('handleLogin');
            return this.$store.dispatch('login', authInfo)
                .then(() => {
                    console.log('login success', authInfo);
                    this.$router.push({ path: '/' })
                })
                .catch(err => this.throwReject(err));
        },
        throwReject (err) { return Promise.reject(err) }
    },
}
</script>

<style scoped>
/* @import "../../assets/bootstrap/css/signin.css"; */html,
body {
  height: 100%;
}

body {
  /* display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center; */
  align-items: center;
  /* padding-top: 40px;
  padding-bottom: 40px; */
  background-color: #f5f5f5;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}
.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

</style>
