<template>
    <footer class="bg-dark">
        <div class="container">
            <div class="row">
                <div class="col py-2 text-center text-white">
                    footer
                </div>
            </div>
        </div>
    </footer>
</template>
<style scoped>
    footer {
        clear: both;
        box-shadow: inset 0 1px 0 rgb(0 0 0 / 10%) !important;
    }
</style>
