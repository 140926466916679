<template>
  <fieldset>
    <label v-for="(option, index) in options" :key="index" class="d-inline-block mr-2 px-2">
        <Field
          type="checkbox"
          :name="name"
          :value="option.value"
          v-model="values"
          @change="updateValue"
        >
        </Field>
        {{ option.label }}
        <!-- <input
          type="checkbox"
          :name="name"
          :value="option.value"
          v-model="values"
          @change="updateValue"
        />
        {{ option.label }} -->
      </label>
  </fieldset>
</template>

<script>
import { Field } from 'vee-validate';

export default {
  name: "BsCheckBox",
  props: {
    options: { type: Array, required: true },
    name: { type: String, required: true },
    modelValue: {
        type: Array,
        required: true
    }
  },
  data() {
    return {
      values: []
    };
  },
  components: {
    Field,
  },
  mounted: function(){
      this.values = this.modelValue;
      console.log('child mounted this.modelValue', this.modelValue);
  },

//   emits: ['update:modelValue'],
  methods: {
    updateValue: function(e) {
        console.log('e change', e);
        console.log('(this.modelValue', this.modelValue);
        console.log('(this.values', this.values);
        // console.log('checkedVals', checkedVals);
        console.log('e.target.value', e.target.value);
      // if (e.target.checked) {
      //   // console.log('checkedVals', checkedVals);
      //   // this.checkedVals.push(e.target.value);
      //   // console.log('push後', this.values);
      // } else {
      //   // this.values = this.values.filter(v => v !== e.target.value);
      // }
      this.$emit("update:modelValue", this.values);
    }
  }
};
</script>
