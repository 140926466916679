export const AUTH_LOGIN = 'AUTH_LOGIN'
// 商品
export const FETCH_ALL_ARTICLELIST = 'FETCH_ALL_ARTICLELIST'
export const UPDATE_ARTICLE = 'UPDATE_ARTICLE'
// タグ（カテゴリ）
export const FETCH_ALL_TAGLIST = 'FETCH_ALL_TAGLIST'
export const UPDATE_TAG = 'UPDATE_TAG'
// タググループ
export const FETCH_ALL_TAGGROUPLIST = 'FETCH_ALL_TAGGROUPLIST'
export const UPDATE_TAGGROUP = 'UPDATE_TAGGROUP'
// 部署
export const FETCH_ALL_DEPARTMENTLIST = 'FETCH_ALL_DEPARTMENTLIST'
export const UPDATE_DEPARTMENT = 'UPDATE_DEPARTMENT'
// 端末
export const FETCH_ALL_DEVICELIST = 'FETCH_ALL_DEVICELIST'
export const UPDATE_DEVICE = 'UPDATE_DEVICE'
// 在庫
export const FETCH_ALL_STOCKLIST = 'FETCH_ALL_STOCKLIST'
export const UPDATE_STOCK = 'UPDATE_STOCK'
// 販売データ
export const FETCH_ALL_SALESLIST = 'FETCH_ALL_SALESLIST'
export const UPDATE_SALES = 'UPDATE_SALES'
// イベント
export const FETCH_ALL_EVENTLIST = 'FETCH_ALL_EVENTLIST'
export const UPDATE_EVENT = 'UPDATE_EVENT'
// 顧客
export const FETCH_ALL_CUSTOMERLIST = 'FETCH_ALL_CUSTOMERLIST'
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER'

export const FETCH_ALL_TASKLIST = 'FETCH_ALL_TASKLIST'
export const ADD_TASK = 'ADD_TASK'
export const UPDATE_TASK = 'UPDATE_TASK'
export const REMOVE_TASK = 'REMOVE_TASK'
export const AUTH_LOGOUT = 'AUTH_LOGOUT'
export const MOVE_TASK_FROM = 'MOVE_TASK_FROM'
export const MOVE_TO_TASK = 'MOVE_TO_TASK'
export const MOVE_TASK_DONE = 'MOVE_TASK_DONE'
