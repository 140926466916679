export default {
    methods: {
        number_format(value){
            return value.toString().replace(
                /(\d+?)(?=(?:\d{3})+$)/g,
                function (x) {
                    return x + ',';
                }
            );
        },
    }
}
