import * as types from './mutation-types'

export default {
  [types.AUTH_LOGIN] (state, payload) {
    state.auth = payload
  },
  [types.AUTH_LOGOUT] (state, payload) {
    state.auth = payload
  },
  [types.FETCH_ALL_ARTICLELIST] (state, payload) {
    // console.log('payload', payload);
    state.apidata.article = payload
  },
  [types.UPDATE_ARTICLE] (state, payload) {
    const article = payload
    // console.log('payload', article.data);
    for (let i = 0; i < state.apidata.article.length; i++) {
      const list = state.apidata.article[i]
      // console.log('article_list', list, list.id, article.id);
      if (list.id !== article.data.id) { continue }
      list.code = article.data.code
      list.label = article.data.label
      list.list_price = article.data.list_price
      list.purchase_price = article.data.purchase_price
      console.log('state.article.lists[i]', state.apidata.article[i]);

      state.apidata.article[i] = list;
      break;
    }
  },
  [types.FETCH_ALL_TAGLIST] (state, payload) {
    state.apidata.tag = payload
  },
  [types.UPDATE_TAG] (state, payload) {
    const data = payload
    // console.log('payload', article.data);
    for (let i = 0; i < state.apidata.tag.length; i++) {
      const list = state.apidata.tag[i]
      // console.log('article_list', list, list.id, article.id);
      if (list.id !== data.data.id) { continue }
      // list.code = data.data.code
      list.label = data.data.label
      // list.list_price = data.data.list_price
      // list.purchase_price = data.data.purchase_price
      // console.log('state.article.lists[i]', state.apidata.tag[i]);

      state.apidata.tag[i] = list;
      break;
    }
  },

  [types.FETCH_ALL_TAGGROUPLIST] (state, payload) {
    state.apidata.tag_group = payload
  },

  [types.FETCH_ALL_DEPARTMENTLIST] (state, payload) {
    state.apidata.department = payload
  },

  [types.UPDATE_DEPARTMENT] (state, payload) {
    const data = payload
    // console.log('payload', article.data);
    for (let i = 0; i < state.apidata.tag.length; i++) {
      const list = state.apidata.tag[i]
      if (list.id !== data.data.id) { continue }
      list.label = data.data.label
      state.apidata.tag[i] = list;
      break;
    }
  },

  [types.FETCH_ALL_DEVICELIST] (state, payload) {
    state.apidata.device = payload
  },
  [types.UPDATE_DEVICE] (state, payload) {
    const data = payload
    // console.log('payload', article.data);
    for (let i = 0; i < state.apidata.device.length; i++) {
      const list = state.apidata.device[i]
      if (list.id !== data.data.id) { continue }
      list.label = data.data.label
      state.apidata.device[i] = list;
      break;
    }
  },

  [types.FETCH_ALL_STOCKLIST] (state, payload) {
    state.apidata.stock = payload
  },
  [types.UPDATE_STOCK] (state, payload) {
    const data = payload
    // console.log('payload', article.data);
    for (let i = 0; i < state.apidata.stock.length; i++) {
      const list = state.apidata.stock[i]
      if (list.id !== data.data.id) { continue }
      list.label = data.data.label
      state.apidata.stock[i] = list;
      break;
    }
  },

  [types.FETCH_ALL_SALESLIST] (state, payload) {
    state.apidata.sales = payload
  },
  [types.UPDATE_SALES] (state, payload) {
    const data = payload
    // console.log('payload', article.data);
    for (let i = 0; i < state.apidata.sales.length; i++) {
      const list = state.apidata.sales[i]
      if (list.id !== data.data.id) { continue }
      list.label = data.data.label
      state.apidata.sales[i] = list;
      break;
    }
  },

  [types.FETCH_ALL_CUSTOMERLIST] (state, payload) {
    state.apidata.customer = payload
  },
  [types.UPDATE_CUSTOMER] (state, payload) {
    const data = payload
    // console.log('payload', article.data);
    for (let i = 0; i < state.apidata.customer.length; i++) {
      const list = state.apidata.customer[i]
      if (list.id !== data.data.id) { continue }
      list.label = data.data.label
      state.apidata.customer[i] = list;
      break;
    }
  },

  [types.FETCH_ALL_EVENTLIST] (state, payload) {
    state.apidata.event = payload
  },
  [types.UPDATE_EVENT] (state, payload) {
    const data = payload
    // console.log('payload', article.data);
    for (let i = 0; i < state.apidata.event.length; i++) {
      const list = state.apidata.event[i]
      if (list.id !== data.data.id) { continue }
      list.label = data.data.label
      state.apidata.event[i] = list;
      break;
    }
  },



//   [types.FETCH_ALL_TASKLIST] (state, payload) {
//     state.board.lists = payload
//   },

//   [types.ADD_TASK] (state, payload) {
//     const task = payload
//     for (let i = 0; i < state.board.lists.length; i++) {
//       const list = state.board.lists[i]
//       if (list.id === task.listId) {
//         list.items.push(task)
//         break
//       }
//     }
//   },

//   [types.UPDATE_TASK] (state, payload) {
//     const task = payload
//     for (let i = 0; i < state.board.lists.length; i++) {
//       const list = state.board.lists[i]
//       if (list.id !== task.listId) { continue }
//       for (let j = 0; j < list.items.length; j++) {
//         const item = list.items[j]
//         if (item.id === task.id) {
//           item.name = task.name
//           item.description = task.description
//           break
//         }
//       }
//     }
//   },

//   [types.REMOVE_TASK] (state, payload) {
//     const { id, listId } = payload
//     for (let i = 0; i < state.board.lists.length; i++) {
//       const list = state.board.lists[i]
//       if (list.id !== listId) { continue }
//       list.items = list.items.filter(item => item.id !== id)
//     }
//   },


//   [types.MOVE_TASK_FROM] (state, payload) {
//     const { target, from } = payload
//     state.dragging.target = target
//     state.dragging.from = from
//   },

//   [types.MOVE_TO_TASK] (state, payload) {
//     const { target, to } = payload
//     state.dragging.target = target
//     state.dragging.to = to
//   },

//   [types.MOVE_TASK_DONE] (state, payload) {
//     const { target, from, to } = payload
//     const getTaskList = (lists, id) => lists.find(list => list.id === id)

//     // ドラッグ&ドロップ処理のための状態をリセット
//     state.dragging.target = null
//     state.dragging.from = null
//     state.dragging.to = null

//     // 移動元のタスクリストから対象タスクを取り出す
//     const fromTaskList = getTaskList(state.board.lists, from)
//     const index = fromTaskList.items.findIndex(item => item.id === target)
//     const task = fromTaskList.items[index]
//     fromTaskList.items.splice(index, 1)

//     // 移動先のタスクリストIDに変更
//     task.listId = to

//     // 移動先にタスクリストに対象タスクを格納
//     const toTaskList = getTaskList(state.board.lists, to)
//     toTaskList.items.push(task)
//   }
}
