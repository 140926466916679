function getToday(){
    const now = new Date();
    let month = now.getMonth() + 1;
    if(month < 10){
        month = '0' + month;
    }
    let day = now.getDate();
    if(day < 10){
        day = '0' + day;
    }
    return now.getFullYear() + '-' + month + '-' + day;
}

export default {
    getToday,
}
