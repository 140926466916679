import client from './client'
import config from '../const'

export default {
    fetch: (token, {data}) => {
        return new Promise((resolve, reject) => {
            const params = new URLSearchParams();
            if(data != undefined){
                const keys = Object.keys(data);
                for(let i=0; i < keys.length; i++){
                    params.append('sprm[' + keys[i] + ']', data[keys[i]]);
                }
            }
            client.post(config.BASE_API_URL + '/event/lists', params, { withCredentials: true, headers: {[config.BASE_API_HEADER_KEY]: token } })
            .then(res => {
                resolve({ sales: res.data.body })
            })
            .catch(err => {
                reject(new Error(err.response.data.message || err.message))
            })
        })
    },
//   add: (token, { name, listId }) => {
//     return new Promise((resolve, reject) => {
//       client.post(`/tasks/add`, { name, listId }, { headers: { 'regiapi-manage-token': token } })
//         .then(res => resolve(res.data))
//         .catch(err => {
//           reject(new Error(err.response.data.message || err.message))
//         })
//     })
//   },

  update: (token, { data }) => {
    return new Promise((resolve, reject) => {
        console.log('device update post data', data);
        const params = new URLSearchParams();
        // let sprm = {};
        if(data != undefined){
            const keys = Object.keys(data);
            for(let i=0; i < keys.length; i++){
                console.log('iprm[' + keys[i] + ']', data[keys[i]]);
                // sprm[keys[i]] = data[keys[i]];
                params.append('iprm[' + keys[i] + ']', data[keys[i]]);
            }
        }
        // console.log('params', params);
      client.post(config.BASE_API_URL + `/event/edit/${data.id}`, params, { headers: {[config.BASE_API_HEADER_KEY]: token } })
        .then(() => resolve())
        .catch(err => {
            console.log(err);
          reject(new Error(err.response.data.message || err.message))
        })
    })
  },

//   remove: (token, { id, listId }) => {
//     return new Promise((resolve, reject) => {
//       client.delete(`/tasks/${id}/remove`, { headers: { 'regiapi-manage-token': token } })
//         .then(() => resolve())
//         .catch(err => {
//           reject(new Error(err.response.data.message || err.message))
//         })
//     })
//   },

//   move: (token, { id, from, to }) => {
//     return new Promise((resolve, reject) => {
//       client.post(`/tasks/${id}/move`, { from, to }, { headers: { 'regiapi-manage-token': token } })
//         .then(() => resolve())
//         .catch(err => {
//           reject(new Error(err.response.data.message || err.message))
//         })
//     })
//   }
}
