import {createRouter, createWebHistory} from 'vue-router'
import routes from './routes'
import { authorizeToken } from './guards'
// import Error from '../components/Error.vue'

// store
// import Store from '@/store/index.js'

const routerHistory = createWebHistory()

const router = createRouter({
  history: routerHistory,
  routes,
})
// console.log('test2');
router.beforeEach(authorizeToken)

// router.beforeEach((to, from, next) => {
//     if (to.matched.some(page => page.meta.isPublic) || Store.state.auth.token) {
//       next()
//     } else {
//       next('/login')
//     }
// });

export default router
